"use client";

// Imports
import React, { ReactNode, useState } from "react";
import {
  Area,
  Benefit,
  City,
  CreateUserStudentLikeDocument,
  FilteredAreas,
  FilteredBenefits,
  FilteredCities,
  FilteredWorkModes,
  FilteredWorkScheduleTypes,
  Job,
  MarkUserStudentTourAsTakenDocument,
  Match,
  ThirdPartyJob,
  UserStudent,
  WorkMode,
  WorkScheduleType,
} from "@koble/graphql";
import { useGraphQLRequest, useMessage } from "@koble/hooks";
import {
  isClientError,
  parseAndDisplayGraphqlErrors,
} from "@koble/utils/src/GraphQL";
import { ClientError } from "graphql-request";

import useUserStudentProfile from "@/common/UserStudentProfileContext/useUserStudentProfile";

import UserStudentExplorePageContext from "./UserStudentExplorePageContext";

// Component declaration
const UserStudentExplorePageProvider = ({
  children,
  defaultJobs,
  defaultThirdPartyJobs,
  defaultLikedJobs,
  defaultMatches,
  count,
  limit,
  page,
  filteredAreas,
  filteredBenefits,
  filteredCities,
  filteredWorkModes,
  filteredWorkScheduleTypes,
  area,
  benefit,
  city,
  workScheduleType,
  workMode,
}: {
  children: ReactNode;
  defaultJobs: Job[];
  defaultThirdPartyJobs: ThirdPartyJob[];
  defaultLikedJobs: Job[];
  defaultMatches: Match[];
  count: number;
  limit: number;
  page: number;
  area?: Area;
  benefit?: Benefit;
  city?: City;
  workScheduleType?: WorkScheduleType;
  workMode?: WorkMode;
  filteredAreas: FilteredAreas[];
  filteredBenefits: FilteredBenefits[];
  filteredCities: FilteredCities[];
  filteredWorkScheduleTypes: FilteredWorkScheduleTypes[];
  filteredWorkModes: FilteredWorkModes[];
}) => {
  // State hooks
  const [jobs] = useState<Job[]>(defaultJobs);
  const [thirdPartyJobs] = useState<ThirdPartyJob[]>(defaultThirdPartyJobs);
  const [likedJobs, setLikedJobs] = useState<Job[]>(defaultLikedJobs);
  const [likingJobId, setLikingJobId] = useState<string>();
  const [matches, setMatches] = useState<Match[]>(defaultMatches);
  const [loading, setLoading] = useState<boolean>(false);

  // Custom hooks
  const { authenticatedGraphQLRequestClient } = useGraphQLRequest();
  const { messageApi } = useMessage();
  const { userStudent, setUserStudent } = useUserStudentProfile();

  // Like creation logic
  const createLike = async (
    jobId: string
  ): Promise<{ job: Job; userStudent: UserStudent; chatId: string } | null> => {
    if (!authenticatedGraphQLRequestClient) return Promise.reject();

    try {
      setLikingJobId(jobId);

      const { createUserStudentLike: isMatch } =
        await authenticatedGraphQLRequestClient.request(
          CreateUserStudentLikeDocument,
          { jobId }
        );

      if (isMatch) {
        // TODO: un hardcode matchId, bring it from the response
        setMatches(
          matches.concat({
            matchId: 0,
            validUntil: new Date(),
            createdAt: new Date(),
            userStudent: {
              userStudentId: userStudent?.userStudentId,
            } as any,
            job: {
              jobId,
            } as any,
          })
        );
      } else {
        const likedJob = jobs.find((job) => job.jobId === jobId);
        if (likedJob) setLikedJobs([...likedJobs, likedJob]);
      }

      messageApi.success("Like enviado con éxito");

      if (!isMatch) return null;

      return {
        job: isMatch.job as Job,
        userStudent: isMatch.userStudent as UserStudent,
        chatId: isMatch.chat?.chatId || "",
      };
    } catch (error) {
      if (isClientError(error)) {
        await parseAndDisplayGraphqlErrors(error as ClientError, messageApi);
      } else {
        messageApi.error("Ocurrió un error al dar like a la vacante");
      }

      return await Promise.reject();
    } finally {
      setLikingJobId(undefined);
    }
  };

  const markTourAsTaken = async (tour: string) => {
    if (!authenticatedGraphQLRequestClient) return;

    try {
      const { markUserStudentTourAsTaken } =
        await authenticatedGraphQLRequestClient.request(
          MarkUserStudentTourAsTakenDocument,
          { tour }
        );

      setUserStudent((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          userStudentTour: {
            hasTakenExploreTour: markUserStudentTourAsTaken || true,
          },
        };
      });
    } catch (error) {
      if (isClientError(error)) {
        await parseAndDisplayGraphqlErrors(error as ClientError, messageApi);
      } else {
        messageApi.error("Ocurrió un error al marcar el tour como tomado");
      }
    }
  };

  // Context provider
  return (
    <UserStudentExplorePageContext.Provider
      value={{
        jobs,
        thirdPartyJobs,
        likedJobs,
        createLike,
        likingJobId,
        setLikingJobId,
        matches,
        filteredAreas,
        filteredBenefits,
        filteredCities,
        filteredWorkModes,
        filteredWorkScheduleTypes,
        area,
        benefit,
        city,
        workScheduleType,
        workMode,
        count,
        limit,
        page,
        loading,
        setLoading,
        markTourAsTaken,
      }}
    >
      {children}
    </UserStudentExplorePageContext.Provider>
  );
};

// Export
export default UserStudentExplorePageProvider;
